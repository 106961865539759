import React from "react";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import DSidebar from "./components/DSidebar";
import Header from "./components/Header";
import { Route, Routes, useLocation } from "react-router";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import "./components/Animation.css";
import Meals from "./pages/meals";
import Categories from "./pages/Category";
import Orders from "./pages/Order";
import SignIn from "./pages/Login";
import Statistics from "./pages/statistics";
import Options from "./pages/Options/Options";

const defaultTheme = createTheme({
  // direction: i18n.language === "en" ? "ltr" : "rtl",
  palette: {
    mode: "light",
    primary: {
      main: "#c87f84",
    },
  },

  typography: {
    fontFamily: '"Montserrat", sans-serif',
    // i18n.language === "ar"
    //   ? '"Alexandria", sans-serif'
    //   : '"Montserrat", sans-serif',
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
  },
});

const App = () => {
  const location = useLocation();
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <Box>{location.pathname !== "/signin" && <DSidebar />}</Box>

        <Box
          sx={{
            // flex: "1",
            width: "calc(100% - 40px - 2rem)",
            ml: "auto",
          }}
        >
          {location.pathname !== "/signin" && (
            <>
              <Header />
            </>
          )}
          <Box pr={1}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/meals" element={<Meals />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/options" element={<Options />} />
              {/* <Route path='/statistics' element={<Statistics />} /> */}
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
