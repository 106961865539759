import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import GridBox from "../../components/GridBox";
import GridItem from "../../components/GridItem";
import MealCard from "../../components/MealCard";
import AddButton from "../../components/AddButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { request } from "../../api/request";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { GetErrorHandler } from "../../components/GetErrorHandlerHelper";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const getCategoryFromServer = () => {
  return request({
    url: "/categories",
  });
};
const getMealsFromServer = () => {
  return request({
    url: "/meals?state=1",
  });
};

const addMealToServer = (values) => {
  return request({
    url: "/meals",
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: values,
  });
};

const Meals = () => {
  const [open, setOpen] = useState(false);
  const [AddFormOpen, setAddFormOpen] = useState(false);
  const [alterMessage, setAlterMessage] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);

  const handleSelectImage = (event) => {
    const file = event.target.files[0];

    setImagePreview(URL.createObjectURL(file));
  };

  const getMealsQuery = useQuery({
    queryKey: ["get-meals-from-server"],
    queryFn: getMealsFromServer,
  });

  const getCategoryQuery = useQuery({
    queryKey: ["get-categoories-in-meals-page"],
    queryFn: getCategoryFromServer,
  });

  const addMealMutation = useMutation({
    mutationKey: ["add-meal-to-category"],
    mutationFn: addMealToServer,
    onError: (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            setAlterMessage("you are not authorize to make this action");
            setMessageType("error");
            setOpen(true);
            break;
          }
          case 422: {
            setAlterMessage("there are some issues with your data");
            setMessageType("error");
            setOpen(true);
            break;
          }
          case 500: {
            setAlterMessage("we have a problem in our server , come later");
            setMessageType("error");
            setOpen(true);
            break;
          }
          case 404: {
            setAlterMessage("we out of space , we can't find your destenation");
            setMessageType("error");
            setOpen(true);
            break;
          }
          default: {
            setAlterMessage(
              "unkown error accoure : request falid with status code" +
                error.response.status
            );
            setMessageType("error");
            setOpen(true);
            break;
          }
        }
      } else if (error.request) {
        setAlterMessage(
          "server response with nothing , Check your internet connection or contact support if the problem persists"
        );
        setMessageType("error");
        setOpen(true);
      } else {
        setAlterMessage("unknow error : " + error.message);
        setMessageType("error");
        setOpen(true);
      }
    },
    onSuccess: () => {
      setAlterMessage("a meal created successfully");
      setMessageType("success");
      setOpen(true);
      setAddFormOpen(false);
      setImagePreview(null);
      getMealsQuery.refetch();
    },
  });

  const handleAlterClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFormClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAddFormOpen(false);
  };
  const HandleFormClick = () => {
    setAddFormOpen(true);
  };

  const handleAddNewMeal = (values) => {
    let data = {
      name: values.name,
      name_ar: values.name_ar,
      description: values.description,
      description_ar: values.description_ar,
      image: values.imageFile,
      category_id: values.category_id,
      price: values.price,
    };

    addMealMutation.mutate(data);
  };

  if (
    getMealsQuery.isLoading ||
    getCategoryQuery.isLoading ||
    addMealMutation.isLoading
  ) {
    return <Loader />;
  }

  if (getMealsQuery.isError) {
    return (
      <GetErrorHandler
        error={getMealsQuery.error}
        refetch={getMealsQuery.refetch}
      />
    );
  }
  if (getCategoryQuery.isError) {
    return (
      <GetErrorHandler
        error={getCategoryQuery.error}
        refetch={getCategoryQuery.refetch}
      />
    );
  }
  return (
    <>
      <Box>
      <div sx={{
        display:'flex'
      }}>
      <Typography
      className='my-text'
      sx={{
         
          transition : '0.4s',
          overflow : 'hidden',
          color : 'black',
          cursor : 'pointer',
          marginBottom:"10px"
      }}
     
  >
      add new product to the menu
  </Typography>
     
        <AddButton reactionFunction={HandleFormClick} />
        </div>
        <GridBox spacing={2}>
          {getMealsQuery.data.data.data.map((meal) => (
            <GridItem
              key={meal.id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ hright: "100%" }}
            >
              <MealCard
                withActions={true}
                setMessageType={setMessageType}
                setAlterOpen={setOpen}
                setAlterMessage={setAlterMessage}
                data={meal}
                refetch={getMealsQuery.refetch}
                categories={getCategoryQuery.data.data.data}
              />
            </GridItem>
          ))}
        </GridBox>
      </Box>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleAlterClose}>
        <Alert
          onClose={handleAlterClose}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {alterMessage}
        </Alert>
      </Snackbar>
      <Dialog
        // sx={{
        //   "& .MuiPaper-root": {
        //     backgroundColor: "#2e2e2e",
        //     color: "#fff",
        //   },
        // }}
        open={AddFormOpen}
        onClose={handleFormClose}
      >
        <DialogTitle>New Product</DialogTitle>
        <DialogContent>
          <Formik
            onSubmit={handleAddNewMeal}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.name && !!errors.name}
                >
                  <InputLabel>Product Name</InputLabel>
                  <OutlinedInput
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    label="Product Name"
                  />
                  {!!touched.name && !!errors.name && (
                    <FormHelperText error>{errors.name}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.name_ar && !!errors.name_ar}
                >
                  <InputLabel>Product Arabic Name</InputLabel>
                  <OutlinedInput
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name_ar}
                    name="name_ar"
                    label="Product Arabic Name"
                  />
                  {!!touched.name_ar && !!errors.name_ar && (
                    <FormHelperText error>{errors.name_ar}</FormHelperText>
                  )}
                </FormControl>
                {/* <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.name_ar && !!errors.name_ar}
                >
                  <InputLabel>Meal Arabic Name</InputLabel>
                  <OutlinedInput
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name_ar}
                    name="name_ar"
                    label="Meal Arabic Name"
                  />
                  {!!touched.name_ar && !!errors.name_ar && (
                    <FormHelperText error>{errors.name_ar}</FormHelperText>
                  )}
                </FormControl> */}
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.description && !!errors.description}
                >
                  <InputLabel>Product Description</InputLabel>
                  <OutlinedInput
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    label="Product Description"
                  />
                  {!!touched.description && !!errors.description && (
                    <FormHelperText error>{errors.description}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.description_ar && !!errors.description_ar}
                >
                  <InputLabel>Product Arabic Description</InputLabel>
                  <OutlinedInput
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description_ar}
                    name="description_ar"
                    label="Product Arabic Description"
                  />
                  {!!touched.description_ar && !!errors.description_ar && (
                    <FormHelperText error>
                      {errors.description_ar}
                    </FormHelperText>
                  )}
                </FormControl>
             {/**    <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.price && !!errors.price}
                >
                  <InputLabel>Product Price</InputLabel>
                  <OutlinedInput
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.price}
                    name="price"
                    label="Product Price"
                  />
                  {!!touched.price && !!errors.price && (
                    <FormHelperText error>{errors.price}</FormHelperText>
                  )}
                </FormControl>
*/}
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  fullWidth
                >
                  Upload a file
                  <VisuallyHiddenInput
                    onChange={(e) => {
                      setFieldValue("imageFile", e.currentTarget.files[0]);
                      handleChange(e);
                      handleSelectImage(e);
                    }}
                    type="file"
                    name="image"
                    value={values.image}
                  />
                </Button>
                {imagePreview && (
                  <Box
                  sx={{
                    textAlign : 'center'
                  }}
                  >
                    <img
                      src={imagePreview}
                      style={{
                        width: "300px",
                        margin : '20px 0',
                        borderRadius: "10px",
                        border: "1px dotted #888",
                      }}
                    />
                  </Box>
                )}
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!touched.category_id && !!errors.category_id}
                >
                   <InputLabel>Category</InputLabel>
                  <Select
                    value={values.category_id}
                    onChange={handleChange}
                    fullWidth
                    label="Category"
                    name="category_id"
                    color="primary"
                    error={!!touched.category && !!errors.category}
                  >
                    {getCategoryQuery.data.data.data.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name.en}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button type="submit" color="primary" variant="contained">
                    add
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const initialValues = {
  name: "",
  name_ar: "",
  price: "3",
  description: "",
  description_ar: "",
  image: "",
  imageFile: {},
  category_id: "",
  calories: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("name field is required"),
  name_ar: Yup.string().required("arabic name field is required"),
  // price: Yup.number().required("price field is requred"),
  description: Yup.string().required("description field is required"),
  description_ar: Yup.string().required("arabic description field is required"),
  image: Yup.string().required("image field is required"),
  category_id: Yup.string().required("category field is required"),
});

export default Meals;
