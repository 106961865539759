import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { request } from "../../api/request";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import { GetErrorHandler } from "../../components/GetErrorHandlerHelper";

const getOrdersFromServer = () => {
  return request({
    url: "/orders",
  });
};

const Orders = () => {
  const ordersQuery = useQuery({
    queryKey: ["get-orders-from-server"],
    queryFn: getOrdersFromServer,
  });

  if (ordersQuery.isLoading) {
    return <Loader />;
  }

  if (ordersQuery.isError) {
    return (
      <GetErrorHandler
        error={ordersQuery.error}
        refetch={ordersQuery.refetch}
      />
    );
  }
  return (
    <Box>
      {ordersQuery?.data?.data?.data?.map((order) => (
        <Box
          key={order.id}
          sx={{
            boxShadow: "inset -2px -2px 15px -5px #00000022",
            p: 4,
            borderRadius: "12px",
            mb: 2,
          }}
        >
             <Typography display={'block'} textAlign={'end'} variant="caption">Ordered at : {order.created_at}</Typography>
          <Typography
            sx={{
              fontWeight: "600",
              mb: 1,
            }}
          >
            Order Number : {order.id}
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              mb: 1,
            }}
          >
            Area Number : {order.table.table_number}
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              mb: 1,
            }}
          >
            Floor : {order.table.floor_name.en}
          </Typography>
     {/**     <Typography
            sx={{
              fontWeight: "600",
              mb: 1,
              textDecoration: "underline",
            }}
          >
            Total Price : {order.total}
          </Typography>
          */} 
          <Divider />
          <Typography
            mb={1}
            sx={{
              fontStyle: "italic",
              textTransform: "capitalize",
              fontWeight: "500",
              fontSize: "19px",
            }}
          >
            Items :
          </Typography>
          {order.order_items.map((orderItem) => (
            <Box
              sx={{
                backgroundColor: "grey.100",
                borderRadius: "6px",
                p: 1,
                mb: 2,
              }}
              key={orderItem.id}
            >
             
              <Typography
                sx={{
                  color: "primary.main",
                  mb: 1,
                  fontWeight: "500",
                }}
              >
                {orderItem.meal.name.en}
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  fontWeight: "700",
                }}
              >
                Quantity : {orderItem.quantity}
              </Typography>

              <Typography
                sx={{
                  fontStyle: "italic",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  fontSize: "16px",
                  mb: 0.5,
                }}
              >
                extra engredient :
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: " center",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {orderItem.meal.optionalIngredients.length === 0 && (
                  <Typography color={"error.light"}>
                    no extra engredient added
                  </Typography>
                )}
                {orderItem.meal.optionalIngredients.map((optional) => (
                  <Box
                    sx={{
                      px: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      backgroundColor: "grey.300",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography>{optional.name.en}</Typography>
                  </Box>
                ))}
              </Box>
            {/**   <Typography>Total Item Price : {orderItem.total}</Typography>
            */}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default Orders;
