import { NotificationsOutlined } from '@mui/icons-material';
import { alpha, Box, Button, IconButton, Typography } from '@mui/material';
import React from 'react';
import { logout, useJawadAuthController } from '../context';
import { useNavigate } from 'react-router';
import logo from  '../assets/images/logo192.png'
const Header = () => {
    const [, dispatch] = useJawadAuthController();
    const navigate = useNavigate();
    
    const logoutUser = () => {
        logout(dispatch, null);
        navigate('/signin');
    };

    return (
        <Box
            sx={{
                height: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                borderRadius: '10px',
                padding: '0px 20px',
                width: 'calc(100% - 10px)',
                marginBottom: '20px',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* Replace with your logo */}
                <img
                    src={logo} // Replace with your logo path
                    alt="logo"
                    style={{ height: '40px', width: '40px', objectFit: 'contain' }}
                />
                <Typography
                    sx={{
                        color: 'primary.main',
                        textTransform: 'capitalize',
                        fontSize: '22px',
                    }}
                >
                    Artista
                </Typography>
            </Box>
            <Box>
                <Button
                    color='error'
                    onClick={logoutUser}
                >
                    Logout
                </Button>
            </Box>
        </Box>
    );
};

export default Header;
