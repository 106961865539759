import { Delete } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { request } from "../api/request";
import { useSnackbar } from "notistack";

const OptionalIngredientCard = ({ optional, withAction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["delete-ingreident"],
    mutationFn: (values) => {
      return request({
        url: `/optionals/${optional.id}`,
        method: "delete",
      });
    },
    onSuccess: () => {
      enqueueSnackbar(optional.name.en + " deleted successfully", {
        variant: "success",
      });
      queryClient.refetchQueries(["get-meals-from-server"]);
    },

    onError: () => {
      enqueueSnackbar("delete " + optional.name.en + " faild", {
        variant: "error",
      });
    },
  });

  const handelDelete = async () => {
    mutation.mutate();
  };

  return (
    <Box
      sx={{
        px: 1,
        display: "flex",
        alignItems: "center",
        gap: "5px",
        backgroundColor: "grey.200",
        borderRadius: "8px",
      }}
    >
      <Typography>{optional.name.en}</Typography>
      {withAction && (
        <IconButton onClick={handelDelete} color="error" size="small">
          <Delete fontSize="small" />
        </IconButton>
      )}

      {mutation.isLoading && <CircularProgress size={20} />}
    </Box>
  );
};

export default OptionalIngredientCard;
