import React from "react";

import "./loader.css";
import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    // <div className="container-loader">
    //   <div className="">
    //     <div>
    //       <svg width="400" height="100" viewBox="0 0 400 100">
    //         <text x="10" y="50" class="text" id="letterG">
    //           L
    //         </text>
    //         <text x="60" y="50" class="text" id="letterO">
    //           E
    //         </text>
    //         <text x="160" y="50" class="text" id="letterD">
    //           S
    //         </text>
    //         <text x="210" y="50" class="text" id="letterI">
    //           A
    //         </text>
    //         <text x="260" y="50" class="text" id="letterV">
    //           L
    //         </text>
    //         <text x="310" y="50" class="text" id="letterA">
    //           O
    //         </text>
    //         <text x="360" y="50" class="text" id="letterA">
    //           N
    //         </text>
    //       </svg>
    //     </div>
    //   </div>
    // </div>
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "0",
          backgroundColor: "#00000022",
        }}
      />
      <CircularProgress sx={{ position: "relative" , zIndex:2 }} />
    </Box>
  );
};

export default Loader;
