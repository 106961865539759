import { ProductionQuantityLimitsOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

const StatisticsCard = ({title , value}) => {
    const randomDegree = Math.floor(Math.random() * 360)
  return (
    <Box
        sx={{
            display : 'flex',
            alignItems : 'center',
            // justifyContent : 'center',
            width : '100%',
            height : '100%',
            padding  : '30px',
            gap : '15px',
            backgroundImage : (theme) => `linear-gradient(${randomDegree}deg ,${theme.palette.primary.dark} , ${theme.palette.primary.light})`,
            borderRadius : '15px'
        }}
    >
        <Box>
            <Typography
                sx={{
                    fontSize : '30px',
                    color : 'white'
                }}
            >
                {value}
            </Typography>
            <Typography
                sx={{
                    color : 'grey.300',
                    fontSize : '16px',
                    fontWeight : 'normal'
                }}
            >
                {title}
            </Typography>
        </Box>
    </Box>
  )
}

export default StatisticsCard