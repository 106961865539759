import { AddOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { request } from "../api/request";
import { useSnackbar } from "notistack";

const AddOptionalButton = ({ meal }) => {
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addMutation = useMutation({
    mutationKey: ["add-optional"],
    mutationFn: (data) => {
      return request({
        url: "/optionals",
        method: "post",
        data,
      });
    },
    onSuccess: () => {
      enqueueSnackbar("new optional created successfully", {
        variant: "success",
      });
      queryClient.refetchQueries(["get-meals-from-server"]);
      handleClose();
    },

    onError: () => {
      enqueueSnackbar("create new optiona faild", {
        variant: "error",
      });
    },
  });
  return (
    <>
      <Tooltip title="add optionals ...">
        <IconButton onClick={handleClickOpen} color="primary" size="small">
          <AddOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Optional To {meal.name.en}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              name_ar: "",
            }}
            validationSchema={yup.object({
              name: yup.string().required("name is required"),
              name_ar: yup.string().required("arabic name is required"),
            })}
            onSubmit={async (values) => {
              await addMutation.mutateAsync({ ...values, meal_id: meal.id });
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!errors.name && !!touched.name}
                >
                  <InputLabel>Name</InputLabel>
                  <OutlinedInput
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {!!errors.name && !!touched.name && (
                    <FormHelperText error>{errors.name}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!errors.name_ar && !!touched.name_ar}
                >
                  <InputLabel>Arabic Name</InputLabel>
                  <OutlinedInput
                    label="Arabic Name"
                    name="name_ar"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name_ar}
                  />
                  {!!errors.name_ar && !!touched.name_ar && (
                    <FormHelperText error>{errors.name_ar}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  disabled={addMutation.isLoading}
                  variant="contained"
                  fullWidth
                >
                  create{" "}
                  {addMutation.isLoading && <CircularProgress size={20} />}
                </Button>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddOptionalButton;
