import {
  alpha,
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FoodBank,
  InfoOutlined,
  StoreRounded,
  Menu,
  MenuOpen,
} from "@mui/icons-material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const menuItems = [
  {
    path: "/",
    title: "Overview",
    icon: <InfoOutlined />,
  },
  {
    path: "/meals",
    title: "Products",
    icon: <FoodBank />,
  },
  {
    path: "/orders",
    title: "Orders history",
    icon: <StoreRounded />,
  },
];

const DSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar initially closed
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isSidebarFullyOpened, setIsSidebarFullyOpened] = useState(false);
  const [isTitleReset, setIsTitleReset] = useState(false);
  const [isTextWhite, setIsTextWhite] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setHighlightedIndex((prev) => {
        if (prev < menuItems.length - 1) return prev + 1;
        clearInterval(interval);
        setTimeout(() => {
          setIsSidebarOpen(true);
          setTimeout(() => {
            setIsSidebarFullyOpened(true);
            setTimeout(() => {
              setIsTitleReset(true);
              setTimeout(() => {
                setIsTextWhite(true); // Change text color to white after scaling
                setTimeout(() => {
                  setIsSidebarOpen(false); // Close the sidebar after animations
                  setIsSidebarFullyOpened(false);
                  setIsTitleReset(false);
                  setIsTextWhite(false); // Reset the text color when closing
                }, 1000); // Adjust delay for sidebar to close after title reset
              }, 500); // Delay to change text color after scale animation
            }, 500); // Wait for sidebar to fully open
          }, 300);
        }, 300);
        return prev;
      });
    }, 300);
    return () => clearInterval(interval);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
      setIsSidebarFullyOpened(false);
      setIsTitleReset(false);
      setIsTextWhite(false);
    }
  };

  const handleSidebarClick = () => {
    if (!isSidebarOpen) {
      setIsSidebarOpen(true);
      setTimeout(() => {
        setIsSidebarFullyOpened(true);
        setTimeout(() => setIsTitleReset(true), 500);
      }, 500);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            border: "none",
            p: 2,
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 1),
            width: isSidebarOpen ? 200 : 60,
            transition: "width 0.3s ease",
            cursor: "pointer",
          },
        }}
        onClick={handleSidebarClick}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isSidebarOpen ? "flex-end" : "center",
            mb: 2,
          }}
        >
          <IconButton onClick={toggleSidebar}>
            {isSidebarOpen ? <MenuOpen /> : <Menu />}
          </IconButton>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: isSidebarOpen ? "flex-start" : "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {menuItems.map((item, index) => (
            <Tooltip
              key={item.path}
              title={isSidebarOpen ? "" : item.title}
              placement="right"
            >
              <Box
                component={Link}
                to={item.path}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: isSidebarOpen ? "10px" : "0",
                  color: isTextWhite ? "#fff" : location.pathname === item.path ? "#fff" : "text.secondary",
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  marginRight:"20px",
                  textDecoration: "none",
                  animation:
                  location.pathname === item.path
                      ? "highlight 0.5s ease"
                      : isSidebarFullyOpened
                      ? "scaleUp 0.5s ease"
                      : "scaleUp 0.5s ease",
                  "@keyframes highlight": {
                    "0%": {
                      filter: "brightness(0.7)",
                      transform: "scale(1)",
                    },
                    "50%": {
                      filter: "brightness(2.4)",
                      transform: "scale(1.6)",
                    },
                    "100%": {
                      filter: "brightness(1)",
                      transform: "scale(1)",
                    },
                  },
                  "@keyframes scaleUp": {
                    "0%": {
                      transform: "scale(1)",
                    },
                    "50%": {
                      transform: "scale(1.6)",
                    },
                    "100%": {
                      transform: "scale(1)",
                    },
                  },
                  "&:hover": {
                    backgroundColor: (theme) => alpha("#fff", 0.3),
                  },
                }}
              >
                <IconButton
                  sx={{
                    color: isTextWhite ? "#fff" : location.pathname === item.path ? "#fff" : "text.secondary",
                  }}
                >
                  {item.icon}
                </IconButton>
                {isSidebarOpen && (
                  <Typography
                    variant="body1"
                    sx={{
                      transition: "color 0.5s ease",
                      color: isTextWhite ? "#fff" : location.pathname === item.path ? "#fff" : "text.secondary",
                    }}
                  >
                    {item.title}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};

export default DSidebar;
